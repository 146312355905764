import { useState, useEffect } from 'react';
import { IEmployee } from 'src/types/Employee';
import { getEmployees } from 'src/services/employeeService';
import { infoFromToken } from 'src/auth';

/**
 * @description
 * Matches the logged in user gotten from Auth0 to the list of employees in Bekk. Returns the logged in user with the information in Bekk's
 * database. The two do not necessarily contain the same information.
 * @returns
 * An IEmployee object with the currently logged in user
 * @returns
 * An IEmployee list holding all Bekk's employees
 */
export const useEmployees = () => {
    const { name, email, id } = infoFromToken();
    const [employees, setEmployees] = useState<IEmployee[] | undefined>();
    const [loggedInEmployee, setLoggedInEmployee] = useState<IEmployee>({
        name,
        email,
        id,
    });
    useEffect(() => {
        getEmployees().then(result => {
            setEmployees(result.filter(x => x.id !== id));
            const loggedInEmployeeFound = result.find(x => x.id === id);
            loggedInEmployeeFound !== undefined &&
                setLoggedInEmployee(loggedInEmployeeFound);
        });
    }, [id]);
    return { employees, loggedInEmployee };
};
