import React from 'react';
import { Link } from 'react-router-dom';
import { isMemberOfSpiritfond } from 'src/auth';
import { useLocation } from 'react-router-dom';

import style from './Header.module.scss';

export const Header = () => {
    const { pathname } = useLocation();
    const isApplication = pathname === '/soknad';
    const isSpiritfond = pathname === '/spiritfond';
    const isAdminPage = pathname === '/adminside';

    return (
        <div
            className={
                !isApplication
                    ? style.headerWrapperColor
                    : style.headerWrapperGray
            }
        >
            <header className={style.header}>
                <Link to="/">
                    <img
                        className={style.headerLogo}
                        src="/img/spiritLogo.svg"
                        alt="Spirit Logo"
                    />
                </Link>
                {!isApplication && (
                    <div className={style.aux}>
                        {isMemberOfSpiritfond() && !isAdminPage && (
                            <Link to="/adminside">
                                <button className={style.seeApplicationsButton}>
                                    Se søknader
                                </button>
                            </Link>
                        )}
                        {!isSpiritfond && !isAdminPage && (
                            <Link to="/spiritfond">
                                <button className={style.applicationButton}>
                                    Søk om støtte
                                </button>
                            </Link>
                        )}
                        <img
                            className={style.piggy}
                            src="/img/oinkoink.svg"
                            alt="lil piggy"
                        />
                    </div>
                )}
            </header>
        </div>
    );
};
