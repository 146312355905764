import React from 'react';
import ReactDOM from 'react-dom';
import { App } from 'src/components/App/App';
import * as serviceWorker from 'src/serviceWorker';
import { getConfig, setConfig } from 'src/config';
import { isAuthenticated, authenticate } from 'src/auth';
import { initializeFirebase } from './firebase';

async function init() {
    const config = await getConfig();
    setConfig(config);
    if (!isAuthenticated()) {
        authenticate();
    } else {
        await initializeFirebase();
        ReactDOM.render(<App />, document.getElementById('root'));
    }
}
init();

serviceWorker.unregister();
