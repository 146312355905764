import React from 'react';
import style from './ApplicationListItem.module.scss';
import { useApplicationContext } from './ApplicationProvider';
import { calculateTotal, currencyFormatter } from '../utils';

interface IProps {
    toggleExpanded: () => void;
    children: JSX.Element;
    dateSent: Date;
}
export const Header = ({ toggleExpanded, children }: IProps) => {
    const { application } = useApplicationContext();

    return (
        <div className={style.header} onClick={() => toggleExpanded()}>
            <h3>{application.form.title}</h3>
            <p>{application.form.dateSent.toLocaleDateString()}</p>
            <h4>
                {currencyFormatter.format(calculateTotal(application))}{' '}
                {application.accountAmount
                    ? `(brukt ${currencyFormatter.format(
                          application.accountAmount,
                      )})`
                    : ''}
            </h4>
            <span className={style.collapseIcon}>{children}</span>
        </div>
    );
};
