import React from 'react';
import style from './GrantedAmountBox.module.scss';
import { IApplication, isPending } from 'src/types/Application';
import { calculateTotal, currencyFormatter } from '../utils';
import { IQuarter } from '../../../types/Quarter';
import { yearlyBudget } from '../../../consts';

interface IProps {
    applicationsByQuarter: Map<IQuarter, IApplication[]>;
    currentQuarter: IQuarter;
}

export const GrantedAmountBox = ({
    applicationsByQuarter,
    currentQuarter,
}: IProps) => {
    const thisYearsApplications = Array.from(applicationsByQuarter.entries())
        .filter(([quarter, _]) => quarter.year === currentQuarter.year)
        .flatMap(([_, applications]) => applications);

    const usedAmount = thisYearsApplications.reduce((acc, val) => {
        return val.accountAmount ? acc + val.accountAmount : acc;
    }, 0);

    const applicationEstimate = thisYearsApplications.reduce(
        (acc, application) => {
            if (isPending(application.form.status)) {
                return acc + calculateTotal(application);
            } else return acc;
        },
        0,
    );

    const previousDeadline = Array.from(applicationsByQuarter.keys())
        .map((quarter) => quarter.processingDeadline)
        .sort((a, b) => (a < b ? 1 : -1))
        .find((date) => date < currentQuarter.processingDeadline);

    const grantedThisQuarter = (previousDeadline: Date) =>
        thisYearsApplications.reduce((acc, application) => {
            if (
                application.form.status === 'Approved' &&
                application.form.dateSent > previousDeadline
            ) {
                return acc + calculateTotal(application);
            } else return acc;
        }, 0);

    const remainingBudget = yearlyBudget - usedAmount;
    const remainingQuarters = 4 - currentQuarter.quarter + 1;

    return (
        <>
            <div className={style.grantedAmountBox}>
                <h3>
                    Q{currentQuarter.quarter} {currentQuarter.year}
                </h3>
                {previousDeadline ? (
                    <>
                        <p>
                            <b>
                                Behandling av søknader fra{' '}
                                {previousDeadline.toLocaleDateString()} t.o.m.{' '}
                                {currentQuarter.processingDeadline.toLocaleDateString()}
                            </b>
                        </p>
                        <p>
                            Godkjente søknader:
                            <br />
                            <b>
                                {currencyFormatter.format(
                                    grantedThisQuarter(previousDeadline),
                                )}
                            </b>
                        </p>
                    </>
                ) : (
                    'Klarte ikke kalkulere innvilget beløp inneværende kvartal fordi previousDeadline ikke kunne utledes'
                )}

                <p>
                    Ubehandlede søknader:
                    <br />
                    <b>{currencyFormatter.format(applicationEstimate)}</b>
                </p>
                <h3>Regnskapsført</h3>
                <p>
                    Beløp brukt så langt i år: <br />
                    <b>
                        {currencyFormatter.format(usedAmount)} /{' '}
                        {currencyFormatter.format(yearlyBudget)}
                    </b>
                </p>
                <p>
                    Estimert kvartalbudsjett (
                    {currencyFormatter.format(remainingBudget)} /{' '}
                    {remainingQuarters}): <br />
                    <b>
                        {currencyFormatter.format(
                            remainingBudget / remainingQuarters,
                        )}
                    </b>
                </p>
            </div>
        </>
    );
};
