import { IApplication } from 'src/types/Application';

export const calculateTotal = (application: IApplication) => {
    const { form, modifiedForm } = application;
    const { budget, maxNumberOfParticipants, participationFeePerPerson } =
        modifiedForm ? modifiedForm : form;

    const amountBeforeFee = budget.reduce((total, item) => {
        const amountForItem =
            item.basis === 'PerPerson'
                ? item.amount * maxNumberOfParticipants
                : item.amount;
        return total + amountForItem;
    }, 0);

    return (
        amountBeforeFee - maxNumberOfParticipants * participationFeePerPerson
    );
};

export const currencyFormatter = new Intl.NumberFormat('no-NO', {
    style: 'currency',
    currency: 'NOK',
    maximumFractionDigits: 0,
});
