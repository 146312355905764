import React, { useState } from 'react';
import { Validation } from 'src/components/Validation/Validation';
import style from './ApplicationTextInput.module.scss';
import { Errors } from 'src/components/Validation/Errors';
import classNames from 'classnames';

interface IProps {
    validatedText: Validation<string>;
    label: string;
    setText: (event: string) => void;
    placeholder: string;
    autoFocus: boolean;
    onFocus: () => void;
}

export const ApplicationTextInput = ({
    validatedText: { errors },
    label,
    setText,
    placeholder,
    autoFocus,
    onFocus,
}: IProps) => {
    const [hasVisited, setHasVisited] = useState<boolean>(false);

    return (
        <div>
            <label className={style.inputLabel}>{label}</label>
            <input
                onBlur={() => setHasVisited(true)}
                onFocus={onFocus}
                type="text"
                placeholder={placeholder}
                onChange={event => setText(event.target.value)}
                className={classNames(style.inputField, {
                    [style.invalidField]: errors.length > 0 && hasVisited,
                })}
                autoFocus={autoFocus}
            ></input>

            <Errors isVisible={hasVisited} errors={errors} />
        </div>
    );
};
