import { useState, useEffect } from 'react';
import {
    getApplications,
    updateApplicationStatus,
    updateApplicationComment,
    updateApplicationAccountAmount,
} from 'src/services/dataService';
import { IApplication, Status } from 'src/types/Application';
import { sendDecisionEmail } from 'src/services/mailService';

export const useApplications = () => {
    const [applications, setApplications] = useState<IApplication[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const setApplication = (app: IApplication) => {
        const updatedApplications = [
            ...applications.filter((x) => x.id !== app.id),
            app,
        ];
        setApplications(updatedApplications);
    };

    const setApplicationInternalComment = async (
        id: string,
        comment: string,
    ) => {
        const application = applications.find((x) => x.id === id);
        if (application) {
            await updateApplicationComment(id, comment);
            setApplications([
                ...applications.filter((x) => x.id !== id),
                {
                    ...application,
                    internalComment: comment,
                },
            ]);
        }
    };

    const setApplicationAccountAmount = async (id: string, amount: number) => {
        const application = applications.find((x) => x.id === id);
        if (application) {
            await updateApplicationAccountAmount(id, amount);
            setApplications([
                ...applications.filter((x) => x.id !== id),
                {
                    ...application,
                    accountAmount: amount,
                },
            ]);
        }
    };

    const setApplicationStatusInProgress = async (id: string) => {
        const application = applications.find((x) => x.id === id);
        if (application) {
            await updateApplicationStatus(id, 'InProgress');
            setApplications([
                ...applications.filter((x) => x.id !== id),
                {
                    ...application,
                    form: { ...application.form, status: 'InProgress' },
                },
            ]);
        }
    };

    const changeApplicationStatus = (
        id: string,
        assessment: string,
        newStatus: Status,
    ) => {
        const appIndex = applications.findIndex((app) => app.id === id);
        if (applications[appIndex]) {
            const newApplications = [...applications];
            const updatedApplication = newApplications[appIndex];
            updatedApplication.form.status = newStatus;
            setApplications(newApplications);
            return updateApplicationStatus(id, newStatus).then(() => {
                if (newStatus === 'Approved') {
                    sendDecisionEmail(
                        true,
                        assessment,
                        updatedApplication.form,
                    );
                } else if (newStatus === 'Rejected') {
                    sendDecisionEmail(
                        false,
                        assessment,
                        updatedApplication.form,
                    );
                }
            });
        }
    };

    const fetchApplications = async () => {
        setIsLoading(true);
        const response = await getApplications();
        setApplications(response);
        setIsLoading(false);
    };
    useEffect(() => {
        fetchApplications();
    }, []);

    return {
        applications,
        isLoading,
        changeApplicationStatus,
        setApplication,
        setApplicationStatusInProgress,
        setApplicationInternalComment,
        setApplicationAccountAmount,
    };
};
