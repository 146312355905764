import React, { useState, useEffect } from 'react';
import { IBudgetItem, ExpenseBasis } from 'src/types/Application';
import style from './BudgetInputEntry.module.scss';
import classNames from 'classnames';

interface IProps {
    onEdit: (newItem: IBudgetItem) => void;
    onDelete: () => void;
    item: IBudgetItem;
    visited: (bothVisited: boolean) => void;
    deletable: boolean;
    onAnyFocus: () => void;
}
export const BudgetInputEntry = ({
    onEdit,
    onDelete,
    item,
    visited,
    deletable,
    onAnyFocus,
}: IProps) => {
    const [activeToggle, setActiveToggle] = useState<ExpenseBasis>('PerPerson');
    const [hasVisitedText, setHasVisitedText] = useState<boolean>(false);
    const [hasVisitedNumber, setHasVisitedNumber] = useState<boolean>(false);

    useEffect(() => {
        visited(hasVisitedText && hasVisitedNumber);
    }, [hasVisitedText, hasVisitedNumber, visited]);

    return (
        <div className={style.budgetEntry}>
            <div className={style.horizontalContainer}>
                <input
                    onBlur={() => {
                        setHasVisitedText(true);
                    }}
                    type="text"
                    className={classNames(style.budgetInputText, {
                        [style.validField]:
                            !hasVisitedText || (hasVisitedText && item.expense),
                        [style.invalidField]: hasVisitedText && !item.expense,
                    })}
                    placeholder="Hva"
                    onChange={e => {
                        onEdit({ ...item, expense: e.target.value });
                        visited(hasVisitedNumber);
                    }}
                    onFocus={onAnyFocus}
                />
                <input
                    onBlur={() => {
                        setHasVisitedNumber(true);
                        visited(hasVisitedText);
                    }}
                    type="number"
                    className={classNames(style.budgetInputValue, {
                        [style.validField]:
                            !hasVisitedNumber ||
                            (hasVisitedNumber && item.amount),
                        [style.invalidField]: hasVisitedNumber && !item.amount,
                    })}
                    placeholder="kr"
                    onChange={e => {
                        onEdit({ ...item, amount: Number(e.target.value) });
                    }}
                    onFocus={onAnyFocus}
                />
            </div>
            <div className={style.horizontalContainer}>
                <div className={style.basisButtonGroup}>
                    <button
                        type="button"
                        onClick={() => {
                            setActiveToggle('PerPerson');
                            onEdit({ ...item, basis: 'PerPerson' });
                        }}
                        className={classNames({
                            [style.basisButtonInactive]:
                                activeToggle !== 'PerPerson',
                            [style.basisButtonActive]:
                                activeToggle === 'PerPerson',
                        })}
                        onFocus={onAnyFocus}
                    >
                        Per deltaker
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            setActiveToggle('Total');
                            onEdit({ ...item, basis: 'Total' });
                        }}
                        className={classNames({
                            [style.basisButtonInactive]:
                                activeToggle !== 'Total',
                            [style.basisButtonActive]: activeToggle === 'Total',
                        })}
                        onFocus={onAnyFocus}
                    >
                        Totalt
                    </button>
                    {deletable && (
                        <button
                            type="button"
                            onClick={onDelete}
                            className={style.deleteButton}
                            onFocus={onAnyFocus}
                        >
                            <img src="/x.svg" alt="X" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
