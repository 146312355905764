import React from 'react';
import { Status } from 'src/types/Application';
import style from './StatusBox.module.scss';
interface IProps {
    status: Status;
}

export const StatusBox = ({ status }: IProps) => {
    switch (status) {
        case 'InProgress':
            return (
                <div className={style.statusBox}>
                    <span className={style.openedIcon} />
                </div>
            );

        case 'UnOpened':
            return (
                <div className={style.statusBox}>
                    <span className={style.unOpenedIcon} />
                </div>
            );

        case 'Approved':
            return (
                <div className={style.statusBox}>
                    <span className={style.approvedIcon} />
                </div>
            );

        case 'Rejected':
            return (
                <div className={style.statusBox}>
                    <span className={style.rejectedIcon} />
                </div>
            );
        default:
            return (
                <div className={style.statusBox}>
                    <span className={style.unOpenedIcon} />
                </div>
            );
    }
};
