import React from 'react';
import style from './GroupInfoWeekly.module.scss';

interface IProps {
    infoType: string;
    infoContentHeader?: string;
    infoContent: string;
}

export const GroupInfoWeekly = ({
    infoType,
    infoContent,
    infoContentHeader,
}: IProps) => {
    return (
        <div className={style.infoBox}>
            <p className={style.infoType}>{infoType}</p>
            <p className={style.infoContentHeader}>{infoContentHeader}</p>
            <p className={style.infoContent}>{infoContent}</p>
        </div>
    );
};
