import { IApplicationForm } from 'src/types/Application';

export const sendConfirmationEmail = (form: IApplicationForm) => {
    fetch('/applicationSendt', {
        method: 'POST',
        body: new URLSearchParams({
            name: form.name,
            applicationUrl: 'https://spirit.bekk.no/adminside', //TODO possibly not the final url we would like to send
            email: form.email,
        }),
    })
        .then(res => console.log(res))
        .catch(err => console.log(err));
};

export const sendDecisionEmail = (
    approved: boolean,
    assessment: string,
    form: IApplicationForm,
) => {
    const { name, email } = form;
    fetch('/decision', {
        method: 'POST',
        body: new URLSearchParams({
            approved: approved.toString(),
            assessment,
            name,
            email,
        }),
    }).catch(err => console.log(err));
};
