import React from 'react';

import { InfoTableEdit } from './InfoTableEdit';
import { ViewInfoTable } from './ViewInfoTable';

interface IViewProps {
    editMode: false;
}

interface IEditProps {
    editMode: boolean;
    setIsInEditMode: (mode: boolean) => void;
}

type IProps = IViewProps | IEditProps;

const isEditableProps = (props: IProps): props is IEditProps => props.editMode;

export const InfoTable = (props: IProps) => {
    return (
        <div>
            {isEditableProps(props) ? (
                <InfoTableEdit setIsInEditMode={props.setIsInEditMode} />
            ) : (
                <ViewInfoTable />
            )}{' '}
        </div>
    );
};
