import React from 'react';
import style from './GroupPage.module.scss';
import { GroupWeekly } from 'src/components/GroupPage/GroupWeekly/GroupWeekly';
import { useFetchApi } from 'src/hooks/useFetchApi';
import { RouteComponentProps } from 'react-router';
import { IGroup } from 'src/types/Group';

type IProps = {
    id: string;
};

export const GroupPage = ({ match }: RouteComponentProps<IProps>) => {
    const group = useFetchApi(
        `/api/group/${match.params.id}`,
        null,
    ) as IGroup | null;

    return (
        <div className={style.groupPageWrapper}>
            <div className={style.groupPageWrapper}>
                {group && <GroupWeekly group={group} />}
            </div>
        </div>
    );
};
