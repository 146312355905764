import React, { useState } from 'react';
import style from './BudgetInput.module.scss';
import { IBudgetItem } from 'src/types/Application';
import { BudgetInputEntry } from './BudgetInputEntry/BudgetInputEntry';
import { Validation } from 'src/components/Validation/Validation';
import { Errors } from 'src/components/Validation/Errors';

interface IProps {
    validatedBudget: Validation<IBudgetItem[]>;
    setBudget: (newBudget: IBudgetItem[]) => void;
    onFocus: () => void;
}

export const BudgetInput = ({
    validatedBudget: { value, errors },
    setBudget,
    onFocus,
}: IProps) => {
    const [hasAnyVisited, setHasAnyVisited] = useState<boolean>(false);
    const addNewBudgetEntry = () => {
        const newBudget = [...value, emptyBudgetItem];
        setBudget(newBudget);
    };

    const emptyBudgetItem: IBudgetItem = {
        expense: '',
        amount: 0,
        basis: 'PerPerson',
    };

    const findAndReplaceBudgetItem = (index: number) => (
        newBudgetItem: IBudgetItem,
    ) => {
        const newBudget = [
            ...value.slice(0, index),
            newBudgetItem,
            ...value.slice(index + 1),
        ];
        setBudget(newBudget);
    };

    const findAndDeleteBudgetItem = (index: number) => () => {
        if (value.length !== 1) {
            const newBudget = [
                ...value.slice(0, index),
                ...value.slice(index + 1),
            ];

            setBudget(newBudget);
        }
    };

    return (
        <div className={style.budgetInputContainer}>
            <label className={style.inputLabel}>Utgifter</label>
            {value.map((item, index) => (
                <BudgetInputEntry
                    key={index}
                    onEdit={findAndReplaceBudgetItem(index)}
                    onDelete={findAndDeleteBudgetItem(index)}
                    item={item}
                    visited={(bothVisited: boolean) => {
                        setHasAnyVisited(bothVisited);
                    }}
                    deletable={value.length > 1}
                    onAnyFocus={onFocus}
                />
            ))}

            <button
                disabled={errors.length > 0}
                type="button"
                className={style.addBudgetEntryButton}
                onClick={addNewBudgetEntry}
                onFocus={onFocus}
            >
                + Legg til utgift
            </button>
            <Errors isVisible={hasAnyVisited} errors={errors} />
        </div>
    );
};
