import React, { useState } from 'react';
import style from './Assessment.module.scss';

interface IProps {
    comment?: string;
    id: string;
    submitComment: (id: string, comment: string) => Promise<void>;
    setShowEditComment: (show: boolean) => void;
}

export const InternalComment = ({
    comment,
    id,
    submitComment,
    setShowEditComment,
}: IProps) => {
    const [editComment, setEditComment] = useState(comment ? comment : '');
    const handleSubmit = async () => {
        await submitComment(id, editComment);
        setShowEditComment(false);
    };
    return (
        <div className={style.assessmentContainer}>
            <label className={style.label}>Kommentar (til internbruk)</label>
            <button
                className={style.abortAssessment}
                onClick={() => setShowEditComment(false)}
            >
                Avbryt
            </button>
            <textarea
                onChange={event => setEditComment(event.target.value)}
                defaultValue={editComment}
            />
            <button className={style.sendButton} onClick={() => handleSubmit()}>
                Lagre
            </button>
        </div>
    );
};
