import React from 'react';
import { Route } from 'react-router-dom';
import { isMemberOfSpiritfond } from 'src/auth';

interface IProps {
    component: React.FC;
    path: string;
}

export const ProtectedAdminPageRoute = ({ component, path }: IProps) => {
    return isMemberOfSpiritfond() ? (
        <Route exact path={path} component={component} />
    ) : (
        <Route
            exact
            path={path}
            render={() => (
                <div>
                    Her har du ikke tilgang{' '}
                    <span role="img" aria-label={'angry-emoji'}>
                        😡
                    </span>
                    . Kontakt de som styrer rettigheter i internsystemene om du
                    mener dette er feil.
                </div>
            )}
        />
    );
};
