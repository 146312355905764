import React, { useState, useEffect } from 'react';
import style from './Assessment.module.scss';
import { getAcceptedText, getDeclinedText } from 'src/assets/email_templates';
import {
    Status,
    IApplicationForm,
    FinishedStatus,
} from 'src/types/Application';
import { IQuarter } from '../../../../types/Quarter';

interface IProps {
    submitApplicationAssessment: (
        id: string,
        assessment: string,
        toggle: FinishedStatus,
    ) => Promise<void> | undefined;
    setShowAssessment: (showAssessment: boolean) => void;
    id: string;
    status: Status;
    form: IApplicationForm;
    quarter: IQuarter;
}

export const Assessment = ({
    submitApplicationAssessment,
    setShowAssessment,
    id,
    status,
    form,
    quarter,
}: IProps) => {
    const [toggle, setToggle] = useState<FinishedStatus>('Approved');
    const [assessment, setAssessment] = useState<string>(
        getAcceptedText(form.name, form.title, quarter),
    );

    useEffect(() => {
        toggle === 'Approved'
            ? setAssessment(getAcceptedText(form.name, form.title, quarter))
            : setAssessment(getDeclinedText(form.name, form.title));
    }, [toggle, form.name, form.title]);

    const handleSubmit = () => {
        const res = submitApplicationAssessment(id, assessment, toggle);
        status !== ('UnOpened' || 'InProgress') &&
            res &&
            res.then(() => {
                setShowAssessment(false);
            });
    };

    return (
        <div className={style.assessmentContainer}>
            <label className={style.label}>Din vurdering</label>
            <button
                className={style.abortAssessment}
                onClick={() => setShowAssessment(false)}
            >
                Avbryt
            </button>
            <div className={style.toggleContainer}>
                <button
                    className={
                        toggle === 'Approved'
                            ? style.toggleAcceptActive
                            : style.toggleAcceptInactive
                    }
                    onClick={() => setToggle('Approved')}
                >
                    Godkjent
                    <span role="img" aria-label="happy-emoji">
                        😁
                    </span>
                </button>
                <button
                    className={
                        toggle === 'Rejected'
                            ? style.toggleDeclineActive
                            : style.toggleDeclineInactive
                    }
                    onClick={() => setToggle('Rejected')}
                >
                    Avslått
                    <span role="img" aria-label="sad-emoji">
                        🙁
                    </span>
                </button>
            </div>

            <label className={style.label}>Tilpass mail til søker</label>
            <textarea
                key={toggle}
                onChange={(event) => setAssessment(event.target.value)}
                defaultValue={
                    toggle === 'Approved'
                        ? getAcceptedText(form.name, form.title, quarter)
                        : getDeclinedText(form.name, form.title)
                }
            />
            <button className={style.sendButton} onClick={() => handleSubmit()}>
                Send
            </button>
        </div>
    );
};
