import React from 'react';
import ReactMarkdown from 'react-markdown';

import { GroupList } from 'src/components/GroupList/GroupList';
import style from './Frontpage.module.scss';
import { useFetchApi } from 'src/hooks/useFetchApi';

import { spiritSlack, signupFormLink } from 'src/consts';

const feedbackText = `Hva synes du om denne siden? Vi setter stor pris 
på [tilbakemelding](https://fridabreivik.typeform.com/to/eSZLlD).`;

export const Frontpage = () => {
    const weeklyGroups = useFetchApi('/api/groups?type=weekly', []);
    const serviceGroups = useFetchApi('/api/groups?type=service', []);
    const periodicGroups = useFetchApi('/api/groups?type=periodic', []);

    return (
        <div className={style.frontpageWrapper}>
            <div className={style.titleView} id="frontpageTitle">
                <div className={style.contentWrapper}>
                    <img
                        className={style.frontpageIllustration}
                        src="/spirit_illustration.svg"
                        alt="Spirit illustrasjon"
                    ></img>
                    <p className={style.titleText}>
                        Spirit er ansvarlig for det sosiale som skjer i Bekk. Vi
                        står bak aktivitetstilbud, fester og Spirit Fond. Join{' '}
                        <a href={spiritSlack}>#spirit-open</a> hvis du lurer på
                        noe, eller <a href={signupFormLink}>bli med!</a>
                    </p>
                </div>
            </div>
            <div className={style.contentView}>
                <div
                    id="Aktiviteter"
                    className={style.contentWrapper}
                    data-aos="fade-up"
                >
                    <GroupList
                        isWeekly={true}
                        title="Ukentlige Aktiviteter"
                        groups={weeklyGroups}
                    />
                    <GroupList
                        isWeekly={false}
                        title="Av og til"
                        groups={periodicGroups}
                    />
                    <GroupList
                        isWeekly={false}
                        title="Goder og tilbud"
                        groups={serviceGroups}
                    />
                </div>
                <div className={style.contentWrapper} data-aos="fade-up">
                    <div className={style.feedback}>
                        <img
                            className={style.feedbackImage}
                            src="/feedback.svg"
                            alt="Gi tilbakemelding på siden!"
                        />
                        <ReactMarkdown
                            className={style.feedbackText}
                            source={feedbackText}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
