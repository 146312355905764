import React from 'react';
import { IBudgetItem } from 'src/types/Application';
import { BudgetEdit } from './BudgetEdit';
import { BudgetView } from './BudgetView';

interface IViewProps {
    editMode: false;
}

interface IEditProps {
    editMode: true;
    setBudget: (budget: IBudgetItem[]) => void;
    budget: IBudgetItem[];
}

type IProps = IViewProps | IEditProps;

const isEditableProps = (props: IProps): props is IEditProps => props.editMode;

export const Budget = (props: IProps) => {
    return (
        <div>
            {isEditableProps(props) ? (
                <BudgetEdit setBudget={props.setBudget} budget={props.budget} />
            ) : (
                <BudgetView />
            )}
        </div>
    );
};
