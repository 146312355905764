import React from 'react';
import style from './GuideBox.module.scss';

export type ApplicationField =
    | 'Aktivitet'
    | 'Søknadstekst'
    | 'Kontaktpersoner'
    | 'Deltakere'
    | 'Utgifter'
    | 'Egenandel';

interface IProps {
    field: ApplicationField;
}

export const GuideText = ({ field }: IProps) => {
    switch (field) {
        case 'Aktivitet':
            return (
                <p>
                    Fortell oss hva du ønsker å arrangere!{' '}
                    <span role="img" aria-label="tada">
                        🎉
                    </span>
                    <br />
                    OBS: Aktiviteten kan ikke være ekstremsport eller kreve noen
                    særlige forkunnskaper.
                </p>
            );
        case 'Søknadstekst':
            return (
                <p>
                    Vi ønsker å vite kort om hvorfor du ønsker å gjennomføre
                    arrangementet, og hva som skal gjøres!{' '}
                    <span
                        role="img"
                        aria-label="grinning_face_with_smiling_eyes"
                    >
                        😄
                    </span>{' '}
                    Er det en aktivitet som har slått an tidligere, eller er det
                    bare noe du synes det hadde vært morsomt å prøve ut?
                </p>
            );
        case 'Kontaktpersoner':
            return (
                <p>
                    Her ønsker vi å vite hvem som har ansvaret for å overholde
                    arrangementet. Du kan fint arrangere alene eller ha med deg
                    noen andre.
                </p>
            );
        case 'Deltakere':
            return (
                <p>
                    I dette feltet setter du en grense på maks antall deltakere
                    på ditt arrangement.
                    <br />
                    OBS: Husk at det kun skal være andre bekkere og må være
                    åpent for alle!
                </p>
            );
        case 'Utgifter':
            return (
                <div>
                    <p>
                        Her fører du inn utgifter du har kunnskap om for
                        arrangementet. Her er et eksempel:
                    </p>
                    <table className={style.budgetTableExample}>
                        <tbody>
                            <tr>
                                <td>Billett</td>
                                <td>-</td>
                                <td>100</td>
                                <td>-</td>
                                <td>Per person</td>
                            </tr>
                            <tr>
                                <td>Mat</td>
                                <td>-</td>
                                <td>5000</td>
                                <td>-</td>
                                <td>Totalt</td>
                            </tr>
                            <tr>
                                <td>Skoutleie</td>
                                <td>-</td>
                                <td>100</td>
                                <td>-</td>
                                <td>Per person</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        OBS: Støtte gis ikke til kostnader direkte knyttet til
                        overnatting, transport til/fra stedet, hotell o.l. Andre
                        utgifter under turen, f.eks. leie av utstyr og transport
                        som er knyttet til opplegget kan støttes.
                    </p>
                </div>
            );
        case 'Egenandel':
            return (
                <p>
                    Det er valgfritt å foreslå en egenandel, men det kan være
                    lurt å innføre det som en forpliktelse til å delta. Vi
                    anbefaler 20% av kostnaden per deltaker.
                    <br />
                    Egenandelen bør ikke endre seg basert på antall påmeldinger,
                    så velg gjerne noe som kan fungere både for høyt og lavt
                    engasjement
                </p>
            );
    }
};
