import React, { useContext, createContext } from 'react';
import { IApplication } from 'src/types/Application';

interface IProps {
    application: IApplication;
    children: JSX.Element;
    setApplication: (app: IApplication) => void;
}

interface IApplicationContext {
    application: IApplication;
    setApplication: (app: IApplication) => void;
}

const ApplicationContext = createContext<IApplicationContext>(
    {} as IApplicationContext,
);

export const useApplicationContext = () => useContext(ApplicationContext);

export const ApplicationProvider = ({
    application,
    children,
    setApplication,
}: IProps) => {
    return (
        <ApplicationContext.Provider value={{ application, setApplication }}>
            {children}
        </ApplicationContext.Provider>
    );
};
