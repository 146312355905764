import React from 'react';
import style from './Errors.module.scss';

interface IProps {
    errors: string[];
    isVisible: boolean;
}

export const Errors = ({ errors, isVisible }: IProps) => {
    return (
        <div className={style.errorContainer}>
            {isVisible && (
                <ul className={style.error}>
                    {errors.map(e => (
                        <li key={e}>{e}</li>
                    ))}
                </ul>
            )}
        </div>
    );
};
