import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { GroupPage } from 'src/components/GroupPage/GroupPage';
import { InfoSubpage } from 'src/components/InfoSubpage/InfoSubpage';
import { Header } from 'src/components/Header/Header';
import { ScrollHandler } from 'src/components/App/ScrollHandler/ScrollHandler';
import { Frontpage } from 'src/components/Frontpage/Frontpage';
import { ApplicationPage } from 'src/components/ApplicationPage/ApplicationPage';
import { AdminPage } from 'src/components/AdminPage/AdminPage';
import { ProtectedAdminPageRoute } from 'src/components/ProtectedRoutes/ProtectedAdminPageRoute';

export const App = () => {
    AOS.init({
        duration: 1000,
    });
    return (
        <BrowserRouter>
            <ScrollHandler />
            <Header />
            <Route exact path="/" component={Frontpage} />
            <Route exact path="/spiritfond" component={InfoSubpage} />
            <Route path="/gruppe/:id" component={GroupPage} />
            <Route exact path="/soknad" component={ApplicationPage} />
            <ProtectedAdminPageRoute path="/adminside" component={AdminPage} />
        </BrowserRouter>
    );
};
