import React from 'react';
import ReactMarkdown from 'react-markdown';

import { InfoContent } from 'src/components/InfoSubpage/InfoContent/InfoContent';
import style from './InfoSubpage.module.scss';
import { useFetchApi } from 'src/hooks/useFetchApi';
import { IInfoSubpage } from 'src/types/InfoSubpage';
import { Link } from 'react-router-dom';

const defaultContent: IInfoSubpage = {
    titleText: `Spiritfond deler ut støtte til arrangementer som kan være til glede for alle i Bekk. Har du en idé til et
    kult arrangement eller aktiviteter?`,
    infoContent: [],
};

export const InfoSubpage = () => {
    const { titleText, infoContent } = useFetchApi(
        '/api/infosubpage',
        defaultContent,
    );

    return (
        <>
            <div className={style.pageWrapper}>
                <div className={style.titleView}>
                    <div className={style.contentWrapper}>
                        <img
                            className={style.frontpageIllustration}
                            src="/spirit_fond.svg"
                            alt="Spirit Fond illustrasjon"
                        />

                        <ReactMarkdown
                            className={style.titleText}
                            source={titleText}
                        />

                        <Link to="/soknad">
                            <button className={style.applicationButton}>
                                Opprett en søknad
                            </button>
                        </Link>
                    </div>
                </div>
                <div className={style.contentView}>
                    <div className={style.contentWrapper}>
                        {infoContent.map((content, index) => (
                            <InfoContent key={index} info={content} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
