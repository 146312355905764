import React, { useState } from 'react';
import style from './ApplicationTextArea.module.scss';
import { Errors } from 'src/components/Validation/Errors';
import { Validation } from 'src/components/Validation/Validation';
import classNames from 'classnames';

interface IProps {
    validatedText: Validation<string>;
    label: string;
    setText: (event: string) => void;
    placeholder: string;
    onFocus: () => void;
}

export const ApplicationTextArea = ({
    validatedText: { errors },
    label,
    setText,
    placeholder,
    onFocus,
}: IProps) => {
    const [hasVisited, setHasVisited] = useState<boolean>(false);

    return (
        <div>
            <label className={style.inputLabel}>{label}</label>
            <textarea
                onBlur={() => setHasVisited(true)}
                onFocus={onFocus}
                className={classNames(style.inputTextArea, {
                    [style.invalidTextArea]: errors.length > 0 && hasVisited,
                })}
                placeholder={placeholder}
                onChange={event => setText(event.target.value)}
            />
            <Errors isVisible={hasVisited} errors={errors} />
        </div>
    );
};
