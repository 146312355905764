let issuerDomain: string;
let audience: string;
let scopes: string;
let employeeSvcUrl: string;
let firebaseApiKey: string;
let firebaseAuthDomain: string;
let firebaseDatabaseURL: string;
let firebaseProjectId: string;
let firebaseAppId: string;
let environment: string;

interface IConfig {
    audience: string;
    issuerDomain: string;
    scopes: string;
    employeeSvcUrl: string;
    firebaseApiKey: string;
    firebaseAuthDomain: string;
    firebaseDatabaseURL: string;
    firebaseProjectId: string;
    firebaseAppId: string;
    environment: string;
}

interface IFirebaseConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    appId: string;
}

export function setConfig(config: IConfig): void {
    issuerDomain = config.issuerDomain;
    audience = config.audience;
    scopes = config.scopes;
    employeeSvcUrl = config.employeeSvcUrl;
    firebaseApiKey = config.firebaseApiKey;
    firebaseAuthDomain = config.firebaseAuthDomain;
    firebaseDatabaseURL = config.firebaseDatabaseURL;
    firebaseAppId = config.firebaseAppId;
    firebaseProjectId = config.firebaseProjectId;
    environment = config.environment;
}

export async function getConfig(): Promise<IConfig> {
    const response = await fetch('/config');
    return response.json() as Promise<IConfig>;
}

export function getIssuerDomain(): string {
    return issuerDomain;
}

export function getAudience(): string {
    return audience;
}

export function getScopes(): string {
    return scopes;
}

export function getEmployeeSvcUrl(): string {
    return employeeSvcUrl;
}

export function getFirestoreApplicationsPath(): string {
    return `applications-${environment}`;
}

export function getFirestoreQuartersPath(): string {
    return `quarters-${environment}`;
}

export function getFirebaseConfig(): IFirebaseConfig {
    return {
        apiKey: firebaseApiKey,
        authDomain: firebaseAuthDomain,
        databaseURL: firebaseDatabaseURL,
        projectId: firebaseProjectId,
        appId: firebaseAppId,
    };
}
