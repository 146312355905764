import React from 'react';
import { slackBase } from 'src/consts';
import { ISlackChannel } from 'src/types/Group';

interface IProps {
    slackChannel: ISlackChannel;
}

export const SlackChannelLink = ({ slackChannel }: IProps) => (
    <a href={`${slackBase}${slackChannel.channelId}`}>
        {slackChannel.channelName}
    </a>
);
