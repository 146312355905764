import { getAudience, getIssuerDomain, getScopes } from 'src/config';

function parseHash(hash: string): any {
    // tslint:disable-next-line:no-any
    const params: any = {};
    const hashes = hash.replace('#', '').split('&');
    for (const h of hashes) {
        const param = h.split('=');
        params[param[0]] = param[1];
    }
    return params;
}

function saveToken(token: string): void {
    try {
        localStorage.setItem('userToken', token);
    } catch (e) {
        alert(
            'Fikk ikke hentet ut userToken fra localStorage. Om du bruker safari i privat mode skru av dette for at siden skal laste :)',
        );
    }
}

export function idToken(): string {
    try {
        return localStorage.getItem('userToken') || '';
    } catch (e) {
        alert(
            'Fikk ikke hentet ut userToken fra localStorage. Om du bruker safari i privat mode skru av dette for at siden skal laste :)',
        );
        return '';
    }
}

function base64ToUtf16(str: string): string {
    return decodeURIComponent(escape(window.atob(str)));
}

function claimsFromToken(jwt: string): any {
    const encoded = jwt && jwt.split('.')[1];
    const jsonString = base64ToUtf16(encoded);
    return JSON.parse(jsonString);
}

export function infoFromToken() {
    const claims = claimsFromToken(idToken());
    return {
        name: claims.name,
        email: claims.email,
        id: claims['https://api.bekk.no/claims/employeeId'],
        permissions: claims['https://api.bekk.no/claims/permission'],
    };
}

export const isMemberOfSpiritfond = () => {
    const spiritfondPermission = 'admin:spiritfond';
    const { permissions } = infoFromToken();
    return permissions.includes(spiritfondPermission);
};

function getApplicationRoot(): string {
    return window.location.origin;
}

function getCurrentState(): string {
    const state = window.location.href.replace(getApplicationRoot(), '');
    return encodeURIComponent(state);
}

function getAuth0Url(): string {
    const encodedCallback = encodeURIComponent(getApplicationRoot());
    const state = getCurrentState();
    const encodedScopes = encodeURIComponent(getScopes());
    return `https://${getIssuerDomain()}/authorize?client_id=${getAudience()}&response_type=token&redirect_uri=${encodedCallback}&scope=${encodedScopes}&state=${state}`;
}

function redirectToAuth0(): void {
    const url = getAuth0Url();
    window.location.replace(url);
}

function getStateFromHash(): string {
    const parameters = parseHash(window.location.hash);
    return decodeURIComponent(parameters.state);
}

function redirectToState(): void {
    const state = getStateFromHash();
    window.location.replace(getApplicationRoot() + state);
}

function hashIsPresent(): boolean {
    return !!window.location.hash;
}

function tryParseToken(): string | null {
    if (hashIsPresent()) {
        const parameters = parseHash(window.location.hash);
        return parameters.id_token;
    }
    return null;
}

function isExpired(jwt: string): boolean {
    const claims = claimsFromToken(jwt);
    const epochNow = new Date().getTime() / 1000;
    return claims.exp <= epochNow - 10;
}

function isInGroup(userGroups: string, allowedGroup: string): boolean {
    return userGroups.indexOf(allowedGroup) >= 0;
}

export function isAuthorized(): boolean {
    const userGroups = claimsFromToken(idToken()).groups;
    return isInGroup(userGroups, 'Alle');
}

export function isAuthenticated(): boolean {
    const userToken = idToken();
    if (userToken) {
        return !isExpired(userToken);
    }
    return false;
}

export function getRoleClaims(): void {
    if (!isAuthenticated) {
        throw new Error('User is not authenticated!');
    }
}

export function authenticate(): void {
    const token = tryParseToken();
    if (token) {
        saveToken(token);
        redirectToState();
    } else {
        redirectToAuth0();
    }
}

export function authenticateUser(): void {
    if (!isAuthenticated()) {
        authenticate();
    }
}
