import React, { useState } from 'react';
import style from './InfoTable.module.scss';
import { IBudgetItem, IModifiedForm } from 'src/types/Application';
import { Budget } from './Budget/Budget';
import { modifyApplicationForm } from 'src/services/dataService';
import { useApplicationContext } from '../ApplicationProvider';

interface IProps {
    setIsInEditMode: (mode: boolean) => void;
}

export const InfoTableEdit = ({ setIsInEditMode }: IProps) => {
    const { application, setApplication } = useApplicationContext();
    const { form, id, modifiedForm } = application;

    const [budget, setBudget] = useState<IBudgetItem[]>(
        modifiedForm ? modifiedForm.budget : form.budget,
    );
    const [maxNumberOfParticipants, setMaxNumberOfParticipants] = useState<
        number
    >(
        modifiedForm
            ? modifiedForm.maxNumberOfParticipants
            : form.maxNumberOfParticipants,
    );
    const [participationFeePerPerson, setParticipationFee] = useState<number>(
        modifiedForm
            ? modifiedForm.participationFeePerPerson
            : form.participationFeePerPerson,
    );

    const hasAddedContactPersons =
        form.contactPersons && form.contactPersons.length > 0;

    const handleSubmit = () => {
        // Create new modified form object based on changes in input
        const form: IModifiedForm = {
            budget,
            maxNumberOfParticipants,
            participationFeePerPerson,
        };

        modifyApplicationForm(id, form).then(() => {
            setApplication({ ...application, modifiedForm: form });
            setIsInEditMode(false);
        });
    };

    const handleCancel = () => {
        setIsInEditMode(false);
    };

    const handleParticipantsEdit = (value: number) => {
        isNaN(value)
            ? setMaxNumberOfParticipants(0)
            : setMaxNumberOfParticipants(value);
    };

    const handleParticipationFeeEdit = (value: number) => {
        isNaN(value) ? setParticipationFee(0) : setParticipationFee(value);
    };

    return (
        <div>
            <dl className={style.descriptionList}>
                <dt>{form.name}</dt>
                <dd>{form.email}</dd>
                {hasAddedContactPersons && (
                    <>
                        <dt>Kontaktpersoner</dt>
                        <dd>
                            <ul className={style.contactPersonsList}>
                                {form.contactPersons.map(x => {
                                    return (
                                        <li key={x.id}>
                                            {x.name} - {x.email}
                                        </li>
                                    );
                                })}
                            </ul>
                        </dd>
                    </>
                )}
                <div className={style.headerInputContainer}>
                    <dt>Maks Deltakere</dt>
                    <input
                        type="number"
                        value={maxNumberOfParticipants}
                        onChange={e =>
                            handleParticipantsEdit(e.target.valueAsNumber)
                        }
                        className={style.inputField}
                    />
                </div>
                &nbsp;
                <div className={style.headerInputContainer}>
                    <dt>Egenandel per deltaker</dt>
                    <input
                        type="number"
                        value={participationFeePerPerson}
                        onChange={e =>
                            handleParticipationFeeEdit(e.target.valueAsNumber)
                        }
                        className={style.inputField}
                    />
                </div>
                <dt>Utgifter</dt>
                <dd className={style.budgetEditContainer}>
                    {form.budget.length} utgift(er){' '}
                    <span className={style.collapseIcon}>{}</span>
                    <Budget editMode setBudget={setBudget} budget={budget} />
                </dd>
            </dl>

            <button className={style.submitButton} onClick={handleSubmit}>
                Fullfør
            </button>
            <button className={style.cancelButton} onClick={handleCancel}>
                Avbryt
            </button>
        </div>
    );
};
