import React, { useState } from 'react';
import style from './InfoTable.module.scss';

import { Budget } from './Budget/Budget';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { useApplicationContext } from '../ApplicationProvider';

export const ViewInfoTable = () => {
    const { application } = useApplicationContext();
    const { form } = application;
    const [showBudget, setShowBudget] = useState(false);

    const modifiableValues = application.modifiedForm
        ? application.modifiedForm
        : application.form;

    const hasAddedContactPersons =
        form.contactPersons && form.contactPersons.length > 0;

    const collapseBudgetIcon = () => {
        if (showBudget) {
            return <MdKeyboardArrowUp size="20px" color="black" />;
        } else {
            return <MdKeyboardArrowDown size="20px" color="black" />;
        }
    };
    return (
        <div>
            <dl className={style.descriptionList}>
                <dt>{form.name}</dt>
                <dd>{form.email}</dd>
                {hasAddedContactPersons && (
                    <>
                        <dt>Kontaktpersoner</dt>
                        <dd
                            style={{
                                backgroundColor: '#fff',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'lightGrey',
                                paddingBottom: '5px',
                            }}
                        >
                            <ul className={style.contactPersonsList}>
                                {form.contactPersons.map(x => {
                                    return (
                                        <li key={x.id}>
                                            {x.name} - {x.email}
                                        </li>
                                    );
                                })}
                            </ul>
                        </dd>
                    </>
                )}

                <dt className={style.maxParticipantsField}>Maks Deltakere</dt>
                <dd>{modifiableValues.maxNumberOfParticipants} stk</dd>
                <dt>Egenandel per deltaker</dt>
                <dd>{modifiableValues.participationFeePerPerson} kr</dd>
                <dt>Utgifter</dt>
                <dd onClick={() => setShowBudget(!showBudget)}>
                    {modifiableValues.budget.length} utgift(er){' '}
                    <span className={style.collapseIcon}>
                        {collapseBudgetIcon()}
                    </span>
                    {showBudget && <Budget editMode={false} />}
                </dd>
            </dl>
        </div>
    );
};
