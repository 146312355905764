import { useEffect, useState } from 'react';
import { IQuarter } from 'src/types/Quarter';
import { getQuarters } from 'src/services/dataService';

export const useQuarters = () => {
    const [quarters, setQuarters] = useState<IQuarter[]>([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchQuarters();
    }, []);

    const fetchQuarters = async () => {
        setIsLoading(true);
        try {
            const response = await getQuarters();
            setQuarters(response);
            setIsLoading(false);
        } catch (err) {
            setError(err);
        }
    };

    const ascendingQuarters = () =>
        quarters.sort((a, b) =>
            a.processingDeadline > b.processingDeadline ? 1 : -1,
        );

    const currentQuarter = (): IQuarter => {
        const currentDate = new Date();
        const quarter = ascendingQuarters().find(
            (q) => q.processingDeadline > currentDate,
        );
        if (quarter) {
            return quarter;
        } else throw new Error('Could not find current quarter');
    };

    return {
        error,
        isLoading,
        ascendingQuarters,
        currentQuarter,
    };
};
