import React, { useState } from 'react';
import style from './ApplicationListItem.module.scss';
import { FinishedStatus, IApplication } from 'src/types/Application';
import { StatusBox } from './StatusBox/StatusBox';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { Body } from './Body';
import { Header } from './Header';
import { Assessment } from './Assessment/Assessment';
import { ApplicationProvider } from './ApplicationProvider';
import { InternalComment } from './Assessment/InternalComment';
import { IQuarter } from '../../../types/Quarter';
import { Account } from './Assessment/Account';

interface IProps {
    application: IApplication;
    submitApplicationAssessment: (
        id: string,
        assessment: string,
        toggle: FinishedStatus,
    ) => Promise<void> | undefined;
    setApplication: (app: IApplication) => void;
    submitInternalComment: (id: string, comment: string) => Promise<void>;
    submitAccountAmount: (id: string, amount: number) => Promise<void>;
    setApplicationStatusInProgress: (id: string) => Promise<void>;
    quarter: IQuarter;
}

export const ApplicationListItem = (props: IProps) => {
    const { application, setApplicationStatusInProgress } = props;
    const [showAssessment, setShowAssessment] = useState<boolean>(false);
    const [showInternalComment, setShowInternalComment] =
        useState<boolean>(false);
    const [showAccount, setShowAccount] = useState<boolean>(false);
    const [expanded, setExpanded] = useState(false);
    const { form, id } = application;

    const CollapseListItemIcon = () => {
        if (expanded) {
            return <MdKeyboardArrowUp size="40px" color="black" />;
        } else {
            return <MdKeyboardArrowDown size="40px" color="black" />;
        }
    };
    const toggleExpanded = () => {
        setExpanded(!expanded);
        if (form.status === 'UnOpened' && expanded)
            setApplicationStatusInProgress(id);
    };
    return (
        <ApplicationProvider
            application={application}
            setApplication={props.setApplication}
        >
            <div className={style.applicationContainer}>
                <div className={style.applicationPreviewContainer}>
                    <div className={style.listItem}>
                        <Header
                            toggleExpanded={toggleExpanded}
                            dateSent={form.dateSent}
                        >
                            <CollapseListItemIcon />
                        </Header>
                        {expanded && (
                            <Body
                                setShowAssessment={setShowAssessment}
                                showAssessment={showAssessment}
                                setShowInternalComment={setShowInternalComment}
                                showInternalComment={showInternalComment}
                                setShowAccount={setShowAccount}
                                showAccount={showAccount}
                            />
                        )}
                    </div>
                    <StatusBox status={form.status} />
                </div>
                {showAssessment && (
                    <Assessment
                        submitApplicationAssessment={
                            props.submitApplicationAssessment
                        }
                        setShowAssessment={setShowAssessment}
                        id={application.id}
                        status={application.form.status}
                        form={form}
                        quarter={props.quarter}
                    />
                )}
                {showInternalComment && (
                    <InternalComment
                        comment={application.internalComment}
                        id={application.id}
                        submitComment={props.submitInternalComment}
                        setShowEditComment={setShowInternalComment}
                    />
                )}
                {showAccount && (
                    <Account
                        accountAmount={application.accountAmount}
                        id={application.id}
                        submitAccountAmount={props.submitAccountAmount}
                        setShowEditAccount={setShowAccount}
                    />
                )}
            </div>
        </ApplicationProvider>
    );
};
