import { useEffect, useState } from 'react';
import { idToken } from 'src/auth';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFetchApi<T>(
    apiUrl: string,
    initialState: T,
    headers?: any,
): T {
    const [data, setData] = useState(initialState);

    useEffect(() => {
        fetch(apiUrl, {
            headers: {
                jwt: idToken(),
                ...headers,
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw new Error("Server didn't like us :(");
                }
            })
            .then(jsonData => setData(jsonData))
            .catch(err => console.log(err));
    }, [apiUrl, headers]);

    return data;
}
