import { IEmployee } from './Employee';

export interface IApplicationForm {
    name: string;
    email: string;
    contactPersons: IEmployee[];
    dateSent: Date;
    applicationText: string;
    participationFeePerPerson: number;
    maxNumberOfParticipants: number;
    status: Status;
    title: string;
    budget: IBudgetItem[];
}

export interface IModifiedForm {
    participationFeePerPerson: number;
    maxNumberOfParticipants: number;
    budget: IBudgetItem[];
}

export interface IApplication {
    id: string;
    form: IApplicationForm;
    feedbackText: string;
    accountAmount?: number;
    modifiedForm?: IModifiedForm;
    internalComment?: string;
}

export interface IBudgetItem {
    expense: string;
    amount: number;
    basis: ExpenseBasis;
}

export type Status = FinishedStatus | PendingStatus;
export type FinishedStatus = 'Approved' | 'Rejected';
export type PendingStatus = 'UnOpened' | 'InProgress';
export const isPending = (status: string): status is PendingStatus =>
    status === 'UnOpened' || status === 'InProgress';

export type ExpenseBasis = 'Total' | 'PerPerson';
