import React from 'react';
import { RemoveIcon } from './RemoveIcon/RemoveIcon';
import style from './ContactPerson.module.scss';
import { IEmployee } from 'src/types/Employee';
import classnames from 'classnames';

interface IProps {
    employee: IEmployee;
    removeClickedElement: (employeeId: number) => void;
    isRemovable?: boolean;
}

export const ContactPerson = ({
    employee,
    removeClickedElement,
    isRemovable,
}: IProps) => {
    return (
        <div
            className={classnames({
                [style.multiSelectSelectedValueRemovable]: isRemovable,
                [style.multiSelectSelectedValue]: !isRemovable,
            })}
        >
            <span>{employee.name}</span>
            {isRemovable && (
                <span>
                    <RemoveIcon
                        onClick={() => removeClickedElement(employee.id)}
                    />
                </span>
            )}
        </div>
    );
};
