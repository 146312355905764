import React, { useState } from 'react';
import style from './ApplicationNumberInput.module.scss';
import { Validation } from 'src/components/Validation/Validation';
import { Errors } from 'src/components/Validation/Errors';
import classNames from 'classnames';

interface IProps {
    validatedNumber: Validation<number>;
    label: string;
    setAmount: (amount: number) => void;
    placeholder: string;
    onFocus: () => void;
}

export const ApplicationNumberInput = ({
    validatedNumber: { errors },
    label,
    setAmount,
    placeholder,
    onFocus,
}: IProps) => {
    const [hasVisited, setHasVisited] = useState<boolean>(false);
    return (
        <div className={style.inputContainer}>
            <label className={style.inputLabel}>{label}</label>
            <input
                className={classNames(style.inputField, {
                    [style.invalidField]: errors.length > 0 && hasVisited,
                })}
                onBlur={() => {
                    setHasVisited(true);
                }}
                type="number"
                placeholder={placeholder}
                onChange={event => setAmount(Number(event.target.value))}
                onFocus={onFocus}
            />
            <Errors isVisible={hasVisited} errors={errors} />
        </div>
    );
};
