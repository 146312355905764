import React, { useState, useRef } from 'react';
import style from './ContactPersons.module.scss';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { ContactPerson } from './ContactPerson/ContactPerson';
import { IEmployee } from 'src/types/Employee';

interface IProps {
    loggedInEmployee: IEmployee;
    selectedElements: IMultiSelectOption[];
    allElements: IMultiSelectOption[] | undefined;
    updateSelection: (selected: IMultiSelectOption[]) => void;
    onFocus: () => void;
}

export const ContactPersons = ({
    loggedInEmployee,
    selectedElements,
    allElements,
    updateSelection,
    onFocus,
}: IProps) => {
    const updateSelectedList = (item: IMultiSelectOption) => {
        const newListElements = [...selectedElements, item];
        updateSelection(newListElements);
    };

    const removeClickedElement = (clickedElementValue: number) => {
        if (clickedElementValue) {
            const newListElements = selectedElements.filter(
                e => clickedElementValue !== e.id,
            );
            updateSelection(newListElements);
        }
    };

    const values = selectedElements.map(employee => {
        return (
            <ContactPerson
                key={employee.id + 'list'}
                employee={employee}
                removeClickedElement={removeClickedElement}
                isRemovable
            />
        );
    });

    return (
        <div className={style.inputContainer}>
            <label className={style.inputLabel}>Kontaktpersoner</label>
            <div>
                <div className={style.flex}>
                    {allElements ? (
                        <ContactPerson
                            employee={loggedInEmployee}
                            removeClickedElement={removeClickedElement}
                        />
                    ) : (
                        <p>Laster ansatte...</p>
                    )}

                    {values}
                </div>

                <Selector
                    placeholder="Sok etter personer her..."
                    allElements={
                        allElements &&
                        allElements.filter(employee =>
                            selectedElements.every(
                                selectedEmployee =>
                                    employee.id !== selectedEmployee.id,
                            ),
                        )
                    }
                    updateSelection={updateSelectedList}
                    onFocus={onFocus}
                />
            </div>
        </div>
    );
};

interface ISelector {
    placeholder: string;
    allElements: IMultiSelectOption[] | undefined;
    updateSelection: (selected: IMultiSelectOption) => void;
    onFocus: () => void;
}

const Selector = ({
    placeholder,
    allElements,
    updateSelection,
    onFocus,
}: ISelector) => {
    const [filterText, setFilterText] = useState('');
    const [listOpen, setListOpen] = useState(false);
    const [cursorIndex, setCursorIndex] = useState<number>(-1);

    const keepOpenRef = useOnClickOutside(() => setListOpen(false));
    const inputRef = useRef<HTMLInputElement>(null);

    const allElementsFiltered =
        allElements &&
        allElements.filter(e => {
            if (!filterText && filterText === '') {
                return true;
            }
            const result =
                filterText && e.name.match(new RegExp(filterText, 'i'));
            return result;
        });

    const focusOnInput = () => {
        const node = inputRef.current;
        if (node) {
            node.focus();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (listOpen) {
            if (event.key === 'ArrowUp' && cursorIndex > 0) {
                setCursorIndex(cursorIndex - 1);
            } else if (
                event.key === 'ArrowDown' &&
                allElementsFiltered &&
                allElementsFiltered.length - 1 > cursorIndex
            ) {
                setCursorIndex(cursorIndex + 1);
            } else if (event.key === 'Enter') {
                if (cursorIndex > -1)
                    allElementsFiltered &&
                        updateSelection(allElementsFiltered[cursorIndex]);
                setCursorIndex(-1);
                setListOpen(false);
                setFilterText('');
            }
        }
    };

    const closeListAndResetCursor = () => {
        setListOpen(false);
        setCursorIndex(-1);
    };

    const openListAndResetCursor = () => {
        setListOpen(true);
        setCursorIndex(-1);
    };

    return (
        <div ref={keepOpenRef}>
            <input
                ref={inputRef}
                onKeyDown={event => handleKeyDown(event)}
                className={style.mutliSelectorInput}
                value={filterText}
                onChange={e => {
                    e.target.value
                        ? openListAndResetCursor()
                        : closeListAndResetCursor();
                    setFilterText(e.target.value);
                }}
                placeholder={allElements ? placeholder : 'Laster ansatte...'}
                onFocus={onFocus}
            />
            {listOpen && (
                <ul className={style.dropdown}>
                    {allElements && allElementsFiltered
                        ? allElementsFiltered.map((item, index) => {
                              return (
                                  <li
                                      key={item.id}
                                      className={
                                          index !== cursorIndex
                                              ? style.dropdownListElementInActive
                                              : style.dropdownListElementActive
                                      }
                                      onClick={() => {
                                          updateSelection(item);
                                          setListOpen(false);
                                          setFilterText('');
                                          focusOnInput();
                                      }}
                                  >
                                      {item.name}
                                  </li>
                              );
                          })
                        : null}
                </ul>
            )}
        </div>
    );
};

export interface IMultiSelectOption {
    name: string;
    id: number;
    email: string;
}
