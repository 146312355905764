import React from 'react';
import Collapsible from 'react-collapsible';
import { Group } from 'src/components/GroupList/Group/Group';
import style from './GroupList.module.scss';
import { IGroup } from 'src/types/Group';

interface IProps {
    isWeekly: boolean;
    title: string;
    groups: IGroup[];
}

export const GroupList = ({ title, isWeekly, groups }: IProps) => {
    return (
        <Collapsible
            open={true}
            trigger={title}
            triggerTagName="h2"
            triggerClassName={style.groupTitleClosed}
            className={style.groupsWrapper}
            openedClassName={style.groupsWrapper}
            triggerOpenedClassName={style.groupTitle}
            contentInnerClassName={style.contentInner}
        >
            <div className={style.groupHeaderWrapper}>
                <span>Hva</span>
                {isWeekly && <span>Når</span>}
                {isWeekly && <span>Hvor</span>}
                {!isWeekly && <span>Hvor ofte</span>}
                <span>Hovedansvarlig</span>
                <span>Slack</span>
            </div>
            <div className={style.groupsBox}>
                {groups.map(group => (
                    <Group key={group.id} group={group} />
                ))}
            </div>
        </Collapsible>
    );
};
