import React from 'react';
import style from './GuideBox.module.scss';

interface IProps {
    isActive: boolean;
    children: JSX.Element;
}

export const GuideBox = ({ children, isActive }: IProps) => (
    <div className={style.guideBoxContainer}>
        <div
            className={isActive ? style.guideBoxActive : style.guideBoxInactive}
        >
            <div className={style.arrowLeft} />
            <div>
                <label className={style.title}>Veiledning</label>
                {children}
            </div>
        </div>
    </div>
);
