import { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

/**
 * Ensures smooth scrolling to #links on the same page with <Link>-elements
 * Just render it inside the router
 */

const ScrollHandlerWithoutRouter = ({ location }: RouteComponentProps) => {
    useEffect(() => {
        const element = document.getElementById(location.hash.replace('#', ''));

        setTimeout(() => {
            window.scrollTo({
                behavior: element ? 'smooth' : 'auto',
                top: element ? element.offsetTop : 0,
            });
        }, 100);
    }, [location]);

    return null;
};

export const ScrollHandler = withRouter(ScrollHandlerWithoutRouter);
