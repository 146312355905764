import { idToken } from 'src/auth';
import { getEmployeeSvcUrl } from 'src/config';
import { stringify } from 'query-string';
import { IEmployee } from 'src/types/Employee';

export const getEmployees = async (): Promise<IEmployee[]> => {
    const token = idToken();
    const employeeServiceUrl = getEmployeeSvcUrl();
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    };
    const params = {
        IncludeNotStarted: false,
        IncludeResigned: false,
        IncludeStillingsgrad: false,
        IncludeRoles: false,
        IncludePersonellResponsible: false,
        Fields: ['id', 'name', 'email'],
    };

    const stringified = stringify(params, { arrayFormat: 'comma' });
    const URL = `${employeeServiceUrl}/v2/employees?${stringified}`;
    const response = await fetch(URL, options);

    if (response.ok) {
        return response.json();
    }

    const result = await response.json();
    throw new Error(result.userMessage);
};
