import React from 'react';

export interface IFeedbackPage {
    success: boolean;
}

export const FeedbackPage = ({ success }: IFeedbackPage) => {
    return (
        <div>
            {success ? (
                <div>
                    <h1>
                        Søknaden din har blitt sendt inn!{' '}
                        <span role="img" aria-label="grin">
                            😃
                        </span>
                    </h1>
                </div>
            ) : (
                <div>
                    <h1>Uffda! Her gikk noe gæli... </h1>
                    <p>
                        Men dette var ikke din feil! Hvis feilen vedvarer, ta
                        kontak med <b>spiritfond@bekk.no</b>, så hjelper de
                        deg:)
                    </p>
                </div>
            )}
        </div>
    );
};
