import React from 'react';
import style from './Budget.module.scss';
import { IBudgetItem } from 'src/types/Application';
import { useApplicationContext } from '../../ApplicationProvider';

export const BudgetView = () => {
    const { application } = useApplicationContext();
    const { form, modifiedForm } = application;
    const { budget } = modifiedForm ? modifiedForm : form;

    const findOldItem = (item: IBudgetItem) =>
        form.budget.find(
            x => x.expense === item.expense && x.basis === item.basis,
        );

    const amountText = (item: IBudgetItem): string => {
        const oldItem = findOldItem(item);
        const trailingString = modifiedForm
            ? oldItem
                ? oldItem.amount === item.amount
                    ? ''
                    : ` (tidligere ${oldItem.amount})`
                : 'Fant ikke gammel verdi'
            : '';
        return `${item.amount} kr ${trailingString}`;
    };

    return (
        <div>
            <div className={style.row}>
                <div className={style.column}>
                    <b>Utgift</b>
                </div>
                <div className={style.column}>
                    <b>Base</b>
                </div>
                <div className={style.column}>
                    <b>Beløp</b>
                </div>
            </div>

            {budget.map(item => {
                return (
                    <div className={style.row} key={item.expense}>
                        <div className={style.column}>{item.expense}</div>
                        <div className={style.column}>
                            {item.basis === 'PerPerson'
                                ? 'per person'
                                : 'total'}
                        </div>

                        <div className={style.column}>{amountText(item)}</div>
                    </div>
                );
            })}
        </div>
    );
};
