import React, { useState } from 'react';
import style from 'src/components/ApplicationPage/ApplicationPage.module.scss';

import { postApplicationForm } from 'src/services/dataService';
import { FeedbackPage } from 'src/components/ApplicationPage/FeedbackPage/FeedbackPage';
import { ApplicationTextArea } from 'src/components/ApplicationPage/ApplicationTextArea/ApplicationTextArea';
import { ApplicationNumberInput } from 'src/components/ApplicationPage/ApplicationNumberInput/ApplicationNumberInput';
import { ApplicationTextInput } from './ApplicationTextInput/ApplicationTextInput';
import { ApplicationSumCalculator } from './ApplicationSumCalculator/ApplicationSumCalculator';

import { IApplicationForm, IBudgetItem } from 'src/types/Application';
import { BudgetInput } from './BudgetInput/BudgetInput';
import { sendConfirmationEmail } from 'src/services/mailService';
import { IEmployee } from 'src/types/Employee';
import { ContactPersons } from './ContactPersons/ContactPersons';
import { useEmployees } from 'src/hooks/useEmployees';
import { useValidatedState } from 'src/components/Validation/Validation';
import { GuideBox } from './GuideBox/GuideBox';
import { ApplicationField, GuideText } from './GuideBox/GuideTexts';

export const ApplicationPage = () => {
    const { employees, loggedInEmployee } = useEmployees();
    const [selectedContactPersons, setSelectedContactPersons] = useState<
        IEmployee[]
    >([]);
    const [validatedTitle, setValidatedTitle] = useValidatedState('', {
        'For kort tittel': title => title.length >= 3,
    });
    const [
        validatedApplicationText,
        setValidatedApplicationText,
    ] = useValidatedState('', {
        'For kort søknadstekst': applicationText =>
            applicationText.length >= 10,
    });
    const [
        validatedMaxNumberOfParticipants,
        setValidatedMaxNumberOfParticipants,
    ] = useValidatedState(0, {
        'Det må være et maks antall deltagere': antall => antall !== 0,
        'Antallet kan ikke være negativt': antall => antall > -1,
    });

    const [
        validatedParticipationFeePerPerson,
        setValidatedParticipationFeePerPerson,
    ] = useValidatedState(0, {});
    const [validatedBudget, setValidatedBudget] = useValidatedState<
        IBudgetItem[]
    >([{ expense: '', amount: 0, basis: 'PerPerson' }], {
        'Alle poster må ha et navn og et tilhørende positivt beløp': budget =>
            budget.length !== 0 &&
            budget.every(entry => entry.amount > 0 && entry.expense !== ''),
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isResolved, setIsResolved] = useState<boolean>(false);

    const [activeField, setActiveField] = useState<ApplicationField>(
        'Aktivitet',
    );

    const handleSubmit = (): void => {
        const form: IApplicationForm = {
            name: loggedInEmployee.name,
            email: loggedInEmployee.email,
            contactPersons: selectedContactPersons,
            dateSent: new Date(),
            title: validatedTitle.value,
            applicationText: validatedApplicationText.value,
            participationFeePerPerson: validatedParticipationFeePerPerson.value,
            maxNumberOfParticipants: validatedMaxNumberOfParticipants.value,
            status: 'UnOpened',
            budget: validatedBudget.value,
        };
        setIsLoading(true);
        postApplicationForm(form)
            .then(() => {
                sendConfirmationEmail(form);
                setIsSuccess(true);
                setIsLoading(false);
                setIsResolved(true);
            })
            .catch(error => {
                console.error(error);
                setIsSuccess(false);
                setIsLoading(false);
                setIsResolved(true);
            });
    };

    const isValidApplication = () => {
        return (
            [
                validatedTitle,
                validatedApplicationText,
                validatedMaxNumberOfParticipants,
                validatedParticipationFeePerPerson,
                validatedBudget,
            ].flatMap(field => field.errors).length === 0
        );
    };

    return (
        <div className={style.pageContainer}>
            <div>
                {!isResolved ? (
                    <form>
                        <div className={style.formOutsideGuidanceGrid}>
                            <h1 className={style.header + ' ' + style.title}>
                                Søknadsskjema
                            </h1>
                        </div>

                        <div className={style.formGuidanceGrid}>
                            <div className={style.componentContainer}>
                                <ApplicationTextInput
                                    autoFocus
                                    validatedText={validatedTitle}
                                    label={'Aktivitet'}
                                    setText={setValidatedTitle}
                                    placeholder={'Hva skal gjøres?'}
                                    onFocus={() => setActiveField('Aktivitet')}
                                />
                            </div>
                            <GuideBox isActive={activeField === 'Aktivitet'}>
                                <GuideText field="Aktivitet" />
                            </GuideBox>
                            <div className={style.componentContainer}>
                                <ApplicationTextArea
                                    validatedText={validatedApplicationText}
                                    label={'Søknadstekst'}
                                    setText={setValidatedApplicationText}
                                    placeholder={'Beskrivelse av aktiviteten'}
                                    onFocus={() =>
                                        setActiveField('Søknadstekst')
                                    }
                                />
                            </div>
                            <GuideBox isActive={activeField === 'Søknadstekst'}>
                                <GuideText field="Søknadstekst" />
                            </GuideBox>
                            <div className={style.componentContainer}>
                                <ContactPersons
                                    loggedInEmployee={loggedInEmployee}
                                    selectedElements={selectedContactPersons}
                                    allElements={employees}
                                    updateSelection={setSelectedContactPersons}
                                    onFocus={() =>
                                        setActiveField('Kontaktpersoner')
                                    }
                                />
                            </div>
                            <GuideBox
                                isActive={activeField === 'Kontaktpersoner'}
                            >
                                <GuideText field="Kontaktpersoner" />
                            </GuideBox>
                            <div className={style.componentContainer}>
                                <ApplicationNumberInput
                                    validatedNumber={
                                        validatedMaxNumberOfParticipants
                                    }
                                    label={'Maks antall deltakere'}
                                    setAmount={
                                        setValidatedMaxNumberOfParticipants
                                    }
                                    placeholder={'Antall'}
                                    onFocus={() => setActiveField('Deltakere')}
                                />
                            </div>
                            <GuideBox isActive={activeField === 'Deltakere'}>
                                <GuideText field="Deltakere" />
                            </GuideBox>

                            <div className={style.componentContainer}>
                                <BudgetInput
                                    validatedBudget={validatedBudget}
                                    setBudget={setValidatedBudget}
                                    onFocus={() => setActiveField('Utgifter')}
                                />
                            </div>
                            <GuideBox isActive={activeField === 'Utgifter'}>
                                <GuideText field="Utgifter" />
                            </GuideBox>
                            <div className={style.componentContainer}>
                                <ApplicationNumberInput
                                    validatedNumber={
                                        validatedParticipationFeePerPerson
                                    }
                                    label={'Egenandel per deltaker'}
                                    setAmount={
                                        setValidatedParticipationFeePerPerson
                                    }
                                    placeholder={'kr'}
                                    onFocus={() => setActiveField('Egenandel')}
                                />
                            </div>
                            <GuideBox isActive={activeField === 'Egenandel'}>
                                <GuideText field="Egenandel" />
                            </GuideBox>
                        </div>

                        <div className={style.formOutsideGuidanceGrid}>
                            <div className={style.componentOutsideGuidanceGrid}>
                                <ApplicationSumCalculator
                                    budget={validatedBudget.value}
                                    participationFeePerPerson={
                                        validatedParticipationFeePerPerson.value
                                    }
                                    maxNumberOfParticipants={
                                        validatedMaxNumberOfParticipants.value
                                    }
                                />
                            </div>
                            <div className={style.componentOutsideGuidanceGrid}>
                                <button
                                    disabled={!isValidApplication()}
                                    className={style.submitButton}
                                    onClick={event => {
                                        event.preventDefault();
                                        handleSubmit();
                                    }}
                                >
                                    Send inn
                                </button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <FeedbackPage success={isSuccess} />
                )}
                {isLoading ? (
                    <div>
                        <p>Loading...</p>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
