import React from 'react';
import style from './Budget.module.scss';
import { IBudgetItem } from 'src/types/Application';

interface IProps {
    setBudget: (budget: IBudgetItem[]) => void;
    budget: IBudgetItem[];
}

export const BudgetEdit = ({ setBudget, budget }: IProps) => {
    const setBudgetItem = (index: number, amount: number) => {
        const newBudgetItem = {
            ...budget[index],
            amount: isNaN(amount) ? 0 : amount,
        };

        const newBudget = [
            ...budget.slice(0, index),
            newBudgetItem,
            ...budget.slice(index + 1),
        ];

        setBudget(newBudget);
    };

    return (
        <div>
            <div className={style.row}>
                <div className={style.column}>
                    <b>Utgift</b>
                </div>
                <div className={style.column}>
                    <b>Base</b>
                </div>
                <div className={style.column}>
                    <b>Beløp</b>
                </div>
            </div>

            {budget.map((item, index) => {
                return (
                    <div className={style.row} key={item.expense}>
                        <div className={style.column}>{item.expense}</div>
                        <div className={style.column}>
                            {item.basis === 'PerPerson'
                                ? 'per person'
                                : 'total'}
                        </div>

                        <div className={style.column}>
                            <input
                                className={style.inputField}
                                type="number"
                                value={item.amount}
                                onChange={e =>
                                    setBudgetItem(index, e.target.valueAsNumber)
                                }
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
