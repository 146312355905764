import React from 'react';
import style from 'src/components/AdminPage/AdminPage.module.scss';
import { useApplications } from 'src/hooks/useApplications';
import { ApplicationListItem } from 'src/components/AdminPage/ApplicationListItem/ApplicationListItem';
import { IApplication, isPending } from 'src/types/Application';
import { IQuarter } from 'src/types/Quarter';
import { useQuarters } from 'src/hooks/useQuarters';
import { GrantedAmountBox } from './GrantedAmountBox/GrantedAmountBox';

export const AdminPage = () => {
    const {
        applications,
        changeApplicationStatus,
        isLoading: isApplicationsLoading,
        setApplication,
        setApplicationStatusInProgress,
        setApplicationInternalComment,
        setApplicationAccountAmount,
    } = useApplications();

    const {
        isLoading: isQuartersLoading,
        ascendingQuarters,
        currentQuarter,
    } = useQuarters();

    const isLoading = isApplicationsLoading || isQuartersLoading;

    if (isLoading) {
        return <h1>Laster inn</h1>;
    } else {
        const quarter = currentQuarter();

        const groupApplicationsByQuarter = (): Map<
            IQuarter,
            IApplication[]
        > => {
            const groupedApplications = new Map<IQuarter, IApplication[]>();
            const findQuarter = (application: IApplication): IQuarter => {
                const applicationDate = application.form.dateSent;
                const quarter = ascendingQuarters().find(
                    (q) => q.processingDeadline >= applicationDate,
                );
                if (quarter) {
                    return quarter;
                } else {
                    throw new Error('Could not find quarter for application');
                }
            };

            applications.forEach((application) => {
                const quarter = findQuarter(application);
                const quarterApplications = groupedApplications.get(quarter);

                if (quarterApplications) {
                    quarterApplications.push(application);
                } else {
                    groupedApplications.set(quarter, [application]);
                }
            });
            return groupedApplications;
        };

        const applicationsByQuarter = groupApplicationsByQuarter();

        return (
            <div className={style.contentWrapper}>
                <div>
                    <h1>
                        Q{quarter.quarter} {quarter.year}
                    </h1>
                </div>

                <div className={style.amountCalculation}>
                    <GrantedAmountBox
                        applicationsByQuarter={applicationsByQuarter}
                        currentQuarter={quarter}
                    />
                </div>
                <div className={style.applicationsList}>
                    <h2> Søknader</h2>
                    <div>
                        {Array.from(applicationsByQuarter.entries())
                            .sort((e1, e2) => {
                                const [q1] = e1;
                                const [q2] = e2;
                                return q1.year !== q2.year
                                    ? q2.year - q1.year
                                    : q2.quarter - q1.quarter;
                            })
                            .map(([quarter, applications]) => (
                                <div key={`${quarter.quarter}${quarter.year}`}>
                                    <h3>
                                        Q{quarter.quarter} {quarter.year}{' '}
                                        <span className={style.deadLine}>
                                            behandlingsfrist{' '}
                                            {quarter.processingDeadline.toLocaleDateString()}
                                        </span>
                                    </h3>

                                    {applications
                                        .sort((app) =>
                                            !isPending(app.form.status)
                                                ? 1
                                                : -1,
                                        )
                                        .map((app) => (
                                            <ApplicationListItem
                                                key={app.id}
                                                application={app}
                                                submitApplicationAssessment={
                                                    changeApplicationStatus
                                                }
                                                setApplication={setApplication}
                                                setApplicationStatusInProgress={
                                                    setApplicationStatusInProgress
                                                }
                                                submitInternalComment={
                                                    setApplicationInternalComment
                                                }
                                                submitAccountAmount={
                                                    setApplicationAccountAmount
                                                }
                                                quarter={quarter}
                                            />
                                        ))}
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        );
    }
};
