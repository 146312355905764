import React from 'react';
import style from './Group.module.scss';
import { IGroup } from 'src/types/Group';
import { SlackChannelLink } from 'src/components/SlackChannelComponent/SlackChannelComponent';

interface IProps {
    group: IGroup;
}

export const Group = ({ group }: IProps) => {
    const {
        name,
        when,
        weeklyTime,
        location,
        responsible,
        slackChannel,
    } = group;
    if (weeklyTime != null) {
        return (
            <div className={style.groupWrapper}>
                <span className={style.groupName}>{name}</span>{' '}
                <span className={style.twoLineInfo}>
                    <p>{weeklyTime.day}er</p>
                    <p>
                        {weeklyTime.startTime}-{weeklyTime.endTime}
                    </p>
                </span>
                <span className={style.twoLineInfo}>
                    <p>{location && location.locationArea}</p>
                    <p>{location && location.locationPlace}</p>
                </span>
                <span>{responsible}</span>
                <span>
                    {slackChannel && (
                        <SlackChannelLink slackChannel={slackChannel} />
                    )}
                </span>
            </div>
        );
    } else {
        return (
            <div className={style.groupWrapper}>
                <span className={style.groupName}>{name}</span>
                <span> {when} </span>
                <span>{responsible}</span>
                <span>
                    {slackChannel && (
                        <SlackChannelLink slackChannel={slackChannel} />
                    )}
                </span>
            </div>
        );
    }
};
