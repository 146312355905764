import React from 'react';
import { SlackChannelLink } from 'src/components/SlackChannelComponent/SlackChannelComponent';
import style from './GroupWeekly.module.scss';
import { IGroup } from 'src/types/Group';
import { GroupInfoWeekly } from 'src/components/GroupPage/GroupWeekly/GroupInfoWeekly/GroupInfoWeekly';

interface IProps {
    group: IGroup;
}

export const GroupWeekly = ({ group }: IProps) => {
    const {
        name,
        slackChannel,
        when,
        location,
        description,
        responsible,
    } = group;
    return (
        <div>
            <div className={style.headerBackground}>
                <span className={style.titleWrapper}>
                    <h1> {name} </h1>
                    <span>
                        {slackChannel && (
                            <SlackChannelLink slackChannel={slackChannel} />
                        )}
                    </span>
                </span>
                <div className={style.infoBoxWrapper}>
                    <GroupInfoWeekly
                        infoType={'Når'}
                        infoContent={when || 'Vet ikke'}
                    ></GroupInfoWeekly>
                    <GroupInfoWeekly
                        infoType={'Hvor'}
                        infoContentHeader={
                            (location && location.locationArea.toUpperCase()) ||
                            'TBD'
                        }
                        infoContent={
                            (location && location.locationPlace) || 'TBD'
                        }
                    ></GroupInfoWeekly>
                    <GroupInfoWeekly
                        infoType={'Hvorfor'}
                        infoContent={'Cuz u be flexible n shit'}
                    ></GroupInfoWeekly>
                </div>
            </div>
            <div className={style.descriptionWrapper}>
                <article className={style.description}>
                    <span className={style.responsible}>
                        <h3>Hovedansvarlig:</h3>
                        <p>{responsible.toUpperCase()}</p>
                    </span>
                    {description}
                </article>
                <div>
                    {/*<img src="mockprofilepic.jpeg" alt="hovedansvarlig bilde"></img>
                    <p>Hovedansvarlig</p>
                    <p>Insert Name Here</p>*/}
                </div>
            </div>
        </div>
    );
};
