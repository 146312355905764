import { getFirestore } from 'src/firebase';
import { IApplicationForm, Status, IModifiedForm } from 'src/types/Application';
import { IApplication } from 'src/types/Application';
import { IQuarter } from 'src/types/Quarter';
import firebase from 'firebase';
import {
    getFirestoreApplicationsPath,
    getFirestoreQuartersPath,
} from 'src/config';

export const postApplicationForm = async (
    applicationSubmitForm: IApplicationForm,
): Promise<boolean> => {
    const db = getFirestore();

    return db
        .collection(getFirestoreApplicationsPath())
        .add(applicationSubmitForm)
        .then(() => {
            console.log('great success');
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
};

export const getApplications = async (): Promise<IApplication[]> => {
    const snap = await getFirestore()
        .collection(getFirestoreApplicationsPath())
        .orderBy('dateSent', 'desc')
        .withConverter(applicationConverter)
        .get();

    return snap.docs.map((doc) => doc.data());
};

export const updateApplicationStatus = async (
    id: string,
    newStatus: Status,
) => {
    const ref = getFirestore()
        .collection(getFirestoreApplicationsPath())
        .withConverter(applicationConverter)
        .doc(id);

    try {
        return ref.update({
            status: newStatus,
        });
    } catch {
        throw new Error('Unable to update application status');
    }
};

export const modifyApplicationForm = async (
    id: string,
    form: IModifiedForm,
) => {
    const ref = getFirestore()
        .collection(getFirestoreApplicationsPath())
        .withConverter(applicationConverter)
        .doc(id);

    try {
        return ref.update({
            modifiedForm: form,
        });
    } catch {
        throw new Error('Unable to modify application');
    }
};

export const updateApplicationComment = async (id: string, comment: string) => {
    const ref = getFirestore()
        .collection(getFirestoreApplicationsPath())
        .withConverter(applicationConverter)
        .doc(id);

    try {
        return ref.update({
            internalComment: comment,
        });
    } catch (err) {
        throw new Error(err);
    }
};

export const updateApplicationAccountAmount = async (
    id: string,
    amount: number,
) => {
    const ref = getFirestore()
        .collection(getFirestoreApplicationsPath())
        .withConverter(applicationConverter)
        .doc(id);

    try {
        return ref.update({
            accountAmount: amount,
        });
    } catch (err) {
        throw new Error(err);
    }
};

export const getQuarters = async (): Promise<IQuarter[]> => {
    const snap = await getFirestore()
        .collection(getFirestoreQuartersPath())
        .withConverter(quarterConverter)
        .get();

    return snap.docs.map((doc) => doc.data());
};

//Object used to define how we convert from firestore to typescript type and vice-versa.
const quarterConverter = {
    toFirestore: (quarter: IQuarter): firebase.firestore.DocumentData => {
        return {
            quarter,
        };
    },
    fromFirestore: (
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions,
    ) => {
        const data = snapshot.data(options);

        const quarterObj: IQuarter = {
            quarter: data.quarter,
            year: data.year,
            processingDeadline: data.processingDeadline.toDate(),
        };

        return quarterObj;
    },
};

const applicationConverter = {
    toFirestore: (
        application: IApplication,
    ): firebase.firestore.DocumentData => {
        return {
            application,
        };
    },
    fromFirestore: (
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions,
    ) => {
        const documentData = snapshot.data(options);

        const applicationObj: IApplication = {
            id: snapshot.id,
            feedbackText: documentData.feedbackText,
            modifiedForm: documentData.modifiedForm,
            internalComment: documentData.internalComment,
            accountAmount: documentData.accountAmount,
            form: {
                name: documentData.name,
                email: documentData.email,
                contactPersons: documentData.contactPersons,
                dateSent: documentData.dateSent.toDate(),
                applicationText: documentData.applicationText,
                participationFeePerPerson:
                    documentData.participationFeePerPerson,
                maxNumberOfParticipants: documentData.maxNumberOfParticipants,
                status: documentData.status,
                title: documentData.title,
                budget: documentData.budget,
            },
        };

        return applicationObj;
    },
};
