import React from 'react';
import Collapsible from 'react-collapsible';

import ReactMarkdown from 'react-markdown';
import style from './InfoContent.module.scss';
import { IInfoContent } from 'src/types/InfoSubpage';

interface IProps {
    info: IInfoContent;
}

export const InfoContent = ({ info }: IProps) => {
    const { text, title } = info;
    return (
        <section>
            <Collapsible
                open={true}
                trigger={title}
                triggerTagName="h2"
                triggerClassName={style.infoTitleClosed}
                className={style.infoWrapper}
                openedClassName={style.infoWrapper}
                triggerOpenedClassName={style.infoTitle}
                contentInnerClassName={style.contentInner}
            >
                <div>
                    <div className={style.textWrapper}>
                        <ReactMarkdown
                            className={style.infoText}
                            source={text}
                        />
                    </div>
                </div>
            </Collapsible>
        </section>
    );
};
