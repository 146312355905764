import React, { FormEvent, useState } from 'react';
import style from './Assessment.module.scss';

interface IProps {
    accountAmount?: number;
    id: string;
    submitAccountAmount: (id: string, accountAmount: number) => Promise<void>;
    setShowEditAccount: (show: boolean) => void;
}

export const Account = ({
    accountAmount,
    id,
    submitAccountAmount,
    setShowEditAccount,
}: IProps) => {
    const [amountField, setAmountField] = useState(
        accountAmount ? accountAmount : 0,
    );

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await submitAccountAmount(id, amountField);
        setShowEditAccount(false);
    };
    return (
        <div className={style.assessmentContainer}>
            <label className={style.label}>Regnskap</label>
            <button
                className={style.abortAssessment}
                onClick={() => setShowEditAccount(false)}
            >
                Avbryt
            </button>
            <div>
                <p>
                    Oppdater dette før neste kvartal skal behandles, slik at man
                    har oversikt over saldo. Beløpet er arrangementets
                    totalkostnad etter egenandel er trukket fra. Dette ser man i
                    Spirit-regnskapet.
                </p>
                <form onSubmit={(event) => handleSubmit(event)}>
                    <label className={style.formLabel} htmlFor="account-amount">
                        Regnskapsført beløp
                    </label>
                    <input
                        id="account-amount"
                        type="number"
                        onChange={(event) => {
                            const value = event.target.valueAsNumber;
                            isNaN(value)
                                ? setAmountField(0)
                                : setAmountField(value);
                        }}
                        value={amountField}
                        className={style.inputField}
                    />
                    <input
                        type="submit"
                        className={style.sendButton}
                        value={'Lagre'}
                    />
                </form>
            </div>
        </div>
    );
};
