import style from './ApplicationListItem.module.scss';
import React, { useState } from 'react';

import { InfoTable } from './InfoTable/InfoTable';
import { useApplicationContext } from './ApplicationProvider';
import { calculateTotal, currencyFormatter } from '../utils';

interface IProps {
    setShowAssessment: (showAssessment: boolean) => void;
    showAssessment: boolean;
    setShowInternalComment: (showInternalComment: boolean) => void;
    showInternalComment: boolean;
    setShowAccount: (setShowAccount: boolean) => void;
    showAccount: boolean;
}

export const Body = ({
    setShowAssessment,
    showAssessment,
    setShowInternalComment,
    showInternalComment,
    setShowAccount,
    showAccount,
}: IProps) => {
    const [isInEditMode, setIsInEditMode] = useState(false);
    const { application } = useApplicationContext();

    const disableAllButtons =
        showAssessment || isInEditMode || showInternalComment || showAccount;

    return (
        <div className={style.content}>
            <p> {application.form.applicationText}</p>
            <InfoTable
                editMode={isInEditMode}
                setIsInEditMode={setIsInEditMode}
            />
            {application.internalComment && (
                <div>
                    <h4 style={{ marginBottom: '0px' }}>Kommentar</h4>
                    <p style={{ marginTop: '5px' }}>
                        {application.internalComment}
                    </p>
                </div>
            )}
            <h4>{currencyFormatter.format(calculateTotal(application))}</h4>
            <div className={style.buttonContainer}>
                <button
                    className={style.makeChangesButton}
                    onClick={() => setShowAssessment(true)}
                    disabled={disableAllButtons}
                >
                    Besvar søknad
                </button>
                <button
                    className={style.makeChangesButton}
                    onClick={() => setIsInEditMode(true)}
                    disabled={disableAllButtons}
                >
                    Gjør endringer
                </button>
                <button
                    className={style.makeChangesButton}
                    onClick={() => setShowInternalComment(true)}
                    disabled={disableAllButtons}
                >
                    Kommenter
                </button>
                {application.form.status === 'Approved' && (
                    <button
                        className={style.makeChangesButton}
                        onClick={() => setShowAccount(true)}
                        disabled={disableAllButtons}
                    >
                        Regnskap
                    </button>
                )}
            </div>{' '}
        </div>
    );
};
