import React from 'react';
import { IBudgetItem } from 'src/types/Application';
import style from './ApplicationSumCalculator.module.scss';

interface IProps {
    budget: IBudgetItem[];
    participationFeePerPerson: number;
    maxNumberOfParticipants: number;
}

export const ApplicationSumCalculator = ({
    budget,
    participationFeePerPerson,
    maxNumberOfParticipants,
}: IProps) => {
    const calculateSum = (): number => {
        const sumPerPersonCosts =
            budget
                .filter(budgetItem => budgetItem.basis === 'PerPerson')
                .map(budgetItem => budgetItem.amount)
                .reduce((total, current) => total + current, 0) *
            maxNumberOfParticipants;

        const sumIndependentCosts = budget
            .filter(budgetItem => budgetItem.basis === 'Total')
            .map(budgetItem => budgetItem.amount)
            .reduce((total, current) => total + current, 0);

        return (
            sumPerPersonCosts +
            sumIndependentCosts -
            maxNumberOfParticipants * participationFeePerPerson
        );
    };

    return (
        <div>
            <p className={style.label}>Summen du søker om:</p>
            <div className={style.number}>{`${calculateSum()
                .toLocaleString('en')
                .replace(',', ' ')} kr`}</div>
        </div>
    );
};
