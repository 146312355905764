import { useEffect, useRef } from 'react';

export function useOnClickOutside(onClickOutside: () => void) {
    const ref = useRef<any>(null);
    useEffect(() => {
        const onClick = (e: any) => {
            if (ref && ref.current) {
                if (ref.current.contains(e.target)) {
                    return;
                }
                onClickOutside();
            }
        };
        document.addEventListener('click', onClick, true);
        return () => document.removeEventListener('click', onClick, true);
    }, [onClickOutside]);
    return ref;
}
